export default Object.freeze({
  'zamna-tulum': {
    name: 'Zamna',
    legend: 'Not Only Electronic Music.',
    logo:
      'https://imgix.cosmicjs.com/845b9de0-cf64-11ee-9ce5-59949019255e-logo-zamna-tulum.png',
    color: '#d2674a',
    secondaryColor: 'rgb(157,207,114)',
    photos: 'brands-zamna-tulum',
    video: {
      title: 'Hall Of Fame: Zamna',
      youtube: 'true',
      content:
        '<strong>Argy & Goom Gum: </strong> A sublime moment, an amazing track, incredible visuals & the perfect weather.',
      id: 'qX9JPWYiFfM',
      videoUrl: 'https://www.youtube.com/embed/qX9JPWYiFfM',
    },
  },
  'afterlife-tulum': {
    name: 'Afterlife',
    legend: 'An odyssey through the realm of consciousness.',
    logo:
      'https://imgix.cosmicjs.com/050b5df0-2f68-11ef-adb1-8b946b3a80e4-logo-afterlife-tulum.png',
    color: '#928b89',
    secondaryColor: 'rgb(215,215,215)',
    photos: 'brands-afterlife-tulum',
    video: {
      title: 'AFTERLIFE TULUM: 2024',
      youtube: 'true',
      content:
        '<b>Anyma & Chris Avantgarde -  Afterlife Tulum</b><p>Afterlife, in partnership with Zamna, has crafted one of the most awe-inspiring audiovisual experiences of our time.</p>',
      id: '82-5u3H1-yk',
      videoUrl: 'https://www.youtube.com/embed/82-5u3H1-yk',
    },
  },
  'ancestral-soul-tulum': {
    name: 'Ancestral Soul',
    legend: 'Connecting you with nature and your ancestors. 🍃',
    logo:
      'https://imgix.cosmicjs.com/e930f090-cf86-11ee-9ce5-59949019255e-logo-ancestral-soul-tulum.png',
    color: '#b7441d',
    secondaryColor: 'rgb(231, 160, 54)',
    photos: 'brand-ancestral-soul-tulum',
    video: {
      title: '🍃 Ancestral Soul',
      content:
        '<p>Ancestral Soul continues crafting memorable installments that ensure a ritualistic experience  through the beats of tribal-influenced Afro-House Music. </p>',
      youtube: false,
      poster:
        'https://imgix.cosmicjs.com/c4778d30-219f-11ee-8167-890d8958890d-Preview-Image-Ancestral-Soul-Video.jpg',
      videoUrl:
        'https://imgix.cosmicjs.com/91854ba0-21a0-11ee-8167-890d8958890d-Video-Ancestral-Soul.MP4',
    },
  },
  'summerians-ibiza-tulum': {
    name: 'Summerians',
    legend: 'A musical & immersive journey through multisensory experiences',
    logo:
      'https://imgix.cosmicjs.com/3fea21a0-cf86-11ee-9ce5-59949019255e-logo-summerians-ibiza-tulum.jpg',
    color: '#efdcd5',
    secondaryColor: '#69282a',

    photos: 'brands-summerians-ibiza-tulum',
    video: {
      title: '📺 IN ACTION: Summerians',
      youtube: 'true',
      content: '<hr> <p>Kintar at Summerians – Tulum (Live Set)\n.The first installment of the seasonal show series </p> ',
      id: 'x1ePVYFl08I?t=390',
      videoUrl: 'https://www.youtube.com/embed/x1ePVYFl08I?t=390',
    },
  },
  'ten-ibiza-tulum': {
    name: 'Ten Ibiza',
    legend: '✖️ A Journey Into House Music',
    logo:
      'https://imgix.cosmicjs.com/1a890d20-cf65-11ee-9ce5-59949019255e-logo-ten-ibiza-tulum.png',
    color: '#411808',
    secondaryColor: '#e19d66',
    photos: 'brands-ten-ibiza-tulum',
    video: {
      title: 'TEN IBIZA',
      content:
        '<p> 🙌 Hands Up! <strong>Ten Ibiza</strong> keeps the party rolling with epic Friday night shows.</p>',
      youtube: false,
      poster:
        'https://imgix.cosmicjs.com/57ed1290-1522-11ee-b5b3-fbd321202bdf-Ten-Ibiza-Brand-foto.jpg',
      videoUrl:
        'https:///imgix.cosmicjs.com/3475a2a0-1522-11ee-b5b3-fbd321202bdf-Ten-Ibiza-Brand.MP4',
    },

  },
  'mystical-sunset-tulum': {
    name: 'Mystical Experiences',
    legend: 'Feelings, Experiences & Music.',
    soundcloud: 'https://soundcloud.com/mystical-experiences',
    logo:
      'https://imgix.cosmicjs.com/9f060a70-cf84-11ee-9ce5-59949019255e-logo-mystical-sunset-tulum.png',
    color: '#98a9ac',
    secondaryColor: '#22616e',
    photos: 'brand-mystical-experiences-tulum',
    video: {
      title: 'Mystical Sunset | 2022 With Mosher',
      youtube: 'true',
      content: '<hr> <p> <strong>Mystical Sunset</strong> has been spinning energetic shows for the past years without interruption. A multitude of international and local artists have collaborated to create the brand signature; powerful dancing sunsets </p>',
      id: 'diQjrckZw5w?t=390',
      videoUrl: 'https://www.youtube.com/embed/diQjrckZw5w?t=390',
    },
  },
  'sinner-tulum': {
    name: 'Sinner',
    logo:
      'https://imgix.cosmicjs.com/f4239a70-cf63-11ee-9ce5-59949019255e-logo-sinner-tulum.png',
    color: '#695b33',
    secondaryColor: '#292828',
    legend: 'Every saint has a past and every sinner has a future',
    photos: 'brand-sinner-tulum',
    video: {
      title: 'Sinner',
      content:
        '<p>A lively atmosphere that takes place inside sinner parties.</p>',
      youtube: false,
      poster:
        'https://imgix.cosmicjs.com/e6047600-a9b9-11ee-b417-db331415685f-thumbnail-video-sinner.jpg',
      videoUrl:
        'https://imgix.cosmicjs.com/debbd640-a9b9-11ee-b417-db331415685f-sinner-video.MP4',
    },
  },
  'day-zero-tulum': {
    name: 'Day Zero',
    legend: 'A new time to be reborn',
    logo:
      'https://imgix.cosmicjs.com/58671430-332d-11ef-a504-63e081e4680f-logo-day-zero-instagram.png',
    secondaryColor: '#cabf83',
    color: '#7c8360',
    photos: 'brands-day-zero-tulum',
    video: {
      title: 'Day Zero Tulum 2023',
      youtube: 'true',
      content:
        '<strong>The aftermovie </strong> Open the doors to a world where nature, myth, and euphoric beats collide.',
      id: '5KWXBb7J_Vg',
      videoUrl: 'https://www.youtube.com/watch?v=5KWXBb7J_Vg  ',
    },
  },
  'dpm-global-tulum': {
    name: 'DPM Global',
    logo:
      'https://imgix.cosmicjs.com/36861ef0-cf65-11ee-9ce5-59949019255e-logo-dpm-global-tulum.png',
    legend: 'Things done one way, DE PUTA MADRE!',
    color: '#4e8c85',
    secondaryColor: '#bc9035',
    photos: 'brands-dpm-global-tulum',
    video: {
      title: '',
      youtube: 'true',
      content: '<hr><p><strong>DPM: &nbsp;</strong>Dive into a Full Day Experience. Dip into a hidden cenote, then lose yourself in the rhythm surrounded by breathtaking scenery.  This is DPM – an unforgettable adventure.  </p>',
      id: 'u9QD93MwBKk',
      videoUrl: 'https://www.youtube.com/embed/u9QD93MwBKk',
    },
  },
  'set-underground-tulum': {
    name: 'SET Underground',
    legend: 'Creating unforgettable experiences',
    logo:
      'https://imgix.cosmicjs.com/62a45480-cf64-11ee-9ce5-59949019255e-logo-set-underground-tulum.png',
    color: '#222b1c',
    secondaryColor: '#181817',
    photos: 'brands-set-underground-tulum',
    video: {
      title: 'Jungle Party w/ Armen Miran',
      youtube: 'true',
      content: '<hr><p><strong>SET UNDERGROUND</strong> fueled Tulum\'s recent party boom. Their unwavering activity even during difficult times helped solidify the vibrant party scene.</p>',
      id: 'QkniYaPbePQ',
      videoUrl: 'https://www.youtube.com/embed/QkniYaPbePQ',
    },
  },
  'piknik-tulum': {
    name: 'PIKNIK',
    legend: 'Delicious musical Selection. Every Saturday.',
    photos: 'brand-piknik-tulum',
    logo:
      'https://imgix.cosmicjs.com/15651790-cf64-11ee-9ce5-59949019255e-logo-piknik-tulum.png',
    color: '#a1e3be',
    secondaryColor: '#fad3f7',
  },
  'vagalume-sessions-tulum': {
    name: 'Vagalume Sessions',
    photos: 'brand-vagalume-sessions',
    legend: 'Sessions',
    logo:
      'https://imgix.cosmicjs.com/60737560-6817-11ee-99b8-c9a431e1a5fa-brAND-IMAGE-SESSIONS.png',
    color: '#aea199',
  },
  'sos-save-our-souls-tulum': {
    name: 'Ritmic',
    legend: 'Magic sets for the true house music lovers.',
    color: '#a7a7a7',
    secondaryColor: '#47b6e9',
    logo: 'https://imgix.cosmicjs.com/e01c9e60-eb5a-11ee-b074-b5c8fe3ef189-logo-sos-save-our-souls-tulum.jpg',
    video: {
      title: 'SOS - JUNGLE PARTY',
      content: '<hr><p> A Sneak Peak: Get to know Save Our Souls Parties in Tulum<br> <strong>SOS:</strong> A digital & vinyl record label focused on underground house music & events.</p>',
      youtube: false,
      poster:
        'https://imgix.cosmicjs.com/1dba4a50-c999-11ec-bea6-99436435ce05-SOSvideo.jpg',
      videoUrl:
        'https://imgix.cosmicjs.com/e999de70-c998-11ec-bea6-99436435ce05-SOSVIDEO.mp4',
    },
  },
  'sundaze-vagalume-tulum': {
    name: 'Sundaze Vagalum',
    legend: 'Sundaze Vagalume',
    color: '#8f684d',
    secondaryColor: '#292016',
    logo:
      'https://imgix.cosmicjs.com/06345ec0-3d1f-11ee-994c-19c2d6a70c1b-SundazeLogo-png-.png',
    video: {
      title: 'Sundaze Dance',
      content: '<hr><p><strong>An epic night</strong> for the archives. <a href="https://notredame.live/">Notre Dame</a> pushing the crowd into the clouds.</p>',
      youtube: false,
      poster:
        'https://imgix.cosmicjs.com/26593640-45fc-11ee-a9a4-e14a982d212b-Preview-image-notre-dame-sundaze.jpg',
      videoUrl:
        'https://imgix.cosmicjs.com/2f6b2450-45fc-11ee-a329-2dbb7031ef3d-Notre-Dame-Past-Party.MOV',
    },
  },
  'circoloco-tulum': {
    name: 'Circoloco',
    legend: 'Legends are made on the dance floor. The clown returns.',
    logo:
      'https://imgix.cosmicjs.com/d9891020-38b1-11ef-a504-63e081e4680f-logo-circoloco-brand.png',
    color: '#d2674a',
    secondaryColor: 'rgb(205,42,0)',
    photos: 'brands-circoloco-tulum',
    video: {
      title: 'Circoloco Tulum 2024',
      youtube: 'mvfCfzZ54a4',
      content:
        '<p>With over 20 years of history in house and techno through its Ibiza DC-10 club parties, Circoloco connects music with fashion, art, and culture.</p>',
      id: 'mvfCfzZ54a4',
      videoUrl: 'https://www.youtube.com/watch?v=mvfCfzZ54a4',
    },
  },
  'disorder-tulum': {
    name: 'Disorder',
    legend: 'Shatter the norm.What will you create?',
    logo:
      '-',
    color: '#d0a497',
    secondaryColor: 'rgb(114,111,111)',
    photos: 'brands-disorder-tulum',
  },
  'core-tulum': {
    name: 'Core',
    legend: 'The center point where borders blur and genres collide.',
    logo:
      'https://imgix.cosmicjs.com/cedeff00-408a-11ef-a504-63e081e4680f-logo-core.png',
    color: '#7a726f',
    secondaryColor: 'rgb(146,108,46)',
    photos: 'brands-core-tulum',
    video: {
      title: 'Tomorrowland presents: CORE Tulum 2024',
      youtube: 'true',
      content:
        '<strong>Core </strong> The evolution is in the center of change.',
      id: 'GuhFwzq1oGE',
      videoUrl: 'https://www.youtube.com/watch?v=GuhFwzq1oGE',
    },
  },
  'mayan-warrior-tulum': {
    name: 'Mayan Warrior',
    legend: 'Honoring tradition & igniting innovation through your inner warrior.',
    logo:
      'https://imgix.cosmicjs.com/5650b710-38ae-11ef-a504-63e081e4680f-mayan-warrior-brand-logo-insta.png',
    color: '#435eb7',
    secondaryColor: 'rgb(30,33,30)',
    photos: 'brands-mayan-warrior-tulum',
    video: {
      title: 'Mayan Warrior - Tulum 2020',
      youtube: 'true',
      content:
        'Unleash the Ancient Spirit: Celebrating the Mayan Warrior, A Yearly Tradition',
      id: 'HzfaMOAQ-90',
      videoUrl: 'https://www.youtube.com/watch?v=HzfaMOAQ-90',
    },
  },
  'ants-tulum': {
    name: 'Ants',
    legend: 'A Colony of Beats. Diverse, Connected, Unforgettable.',
    logo:
      'https://imgix.cosmicjs.com/83ade300-38b2-11ef-a504-63e081e4680f-logo-ants-brand.png',
    color: '#512828',
    secondaryColor: '#291616',
    photos: 'brands-ants-tulum',
    video: {
      title: 'ANTS Tulum | Official Aftermovie',
      youtube: 'true',
      content:
        '💫 A Magical Edition with Adam Beyer, Joris Voorn, Andrea Oliva b2b Ilario Alicante, Chelina Manuhutu and Francisco Allendes.',
      id: 'Rs9_h-b1pIU',
      videoUrl: 'https://www.youtube.com/watch?v=Rs9_h-b1pIU',
    },
  },
  'renaissance-tulum': {
    name: 'Renaissance',
    legend: 'Music Makers. From silence, a rebirth.',
    logo:
      'https://imgix.cosmicjs.com/d9245050-38b0-11ef-a504-63e081e4680f-logo-renaissance-brand.png',
    color: '#944a9f',
    secondaryColor: 'rgb(165,99,165)',
    photos: 'brands-renaissance-tulum',
    video: {
      title: '📺 Renaissance Tulum Jan, 2022',
      youtube: 'true',
      content:
        'Zamna Festival (Official aftermovie)',
      id: 'UWHD6NKTUxU',
      videoUrl: 'https://www.youtube.com/watch?v=UWHD6NKTUxU',
    },
  },
  'keinemusik-tulum': {
    name: 'Keinemusik',
    legend: 'A networks core, like clouds that gather, forevermore.',
    logo:
      'https://imgix.cosmicjs.com/de2b3700-3e4e-11ef-a504-63e081e4680f-logo-keinemusik-brand.png',
    color: '#d2c6c0',
    secondaryColor: 'rgb(159,151,131)',
    photos: 'brands-keinemusik-tulum',
    video: {
      title: 'KEINEMUSIK TULUM (Official aftermovie)\n',
      youtube: 'true',
      content:
        '💫 The Kloud Landed Over Tulum on January 9th, 2024.',
      id: '4ZbIppO904A',
      videoUrl: 'https://www.youtube.com/watch?v=Rs9_h-b1pIU',
    },
  },
  'zamna-on-the-beach': {
    name: 'Zamna On The Beach',
    legend: 'A cherished keepsake in the hearts of all who had witnessed it.',
    logo:
      'https://imgix.cosmicjs.com/c4e1b280-b278-11ef-bee4-3bb1d3c55332-zamna-logo-instagram.png',
    color: '#645d5c',
    secondaryColor: 'rgb(108,148,152)',
    photos: 'brands-zamna-on-the-beach',
    video: {
      title: 'Renaissance On The Beach',
      youtube: 'yes',
      content:
        '<b>Zamna On The Beach:</b> <br>🥇 A golden beach side experience, top artists & top blue shades',
      id: 'CMkFSBSpo1w',
      videoUrl: 'https://www.youtube.com/watch?v=CMkFSBSpo1w',
    },
  },
  'ritmic-vagalume-tulum': {
    name: 'Ritmic',
    legend: 'The Right Order Of Sounds.',
    color: 'rgb(43 33 25)',
    secondaryColor: '#cfdbcc',
  },
  'awake-tulum': {
    name: 'Awake Tulum',
    legend: 'Where thoughts transcend & senses come alive ',
    color: 'rgb(43 33 25)',
    secondaryColor: '#116bd7',

  },
  'gypsy-disco-gitano-tulum': {
    name: 'Gypsy Disco',
    legend: 'Be Gypsy',
    logo: 'https://imgix.cosmicjs.com/9dca4c20-d4d4-11ee-9ce5-59949019255e-logo-gitano-tulum.png',
    color: 'rgb(43 33 25)',
    secondaryColor: '#475433',
  },
});


`




`
